import ConnectWithMe from "../components/ConnectWithMe";
import ContentWrapper from "../components/ContentWrapper";
import HireMe from "../components/HireMe";
import PageWrapper from "../components/PageWrapper";

const ContactContent = () => {
  return (
    <>
      <ConnectWithMe />
      <HireMe />
    </>
  );
};

const ContactRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <ContactContent />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ContactRoute;
