import SocialMedia from "./SocialMedia";
import Flex from "./Flex";

const Footer = () => {
  return (
    <Flex.ColumnCenter
      style={{
        background:
          "linear-gradient(127deg, rgb(205,236,218), rgba(0, 0, 0, 0) 70.71%), linear-gradient(217deg, rgb(255,134,116), rgba(0, 255, 0, 0) 70.71%), linear-gradient(336deg, rgb(252,224,167), rgba(0, 0, 0, 0) 70.71%)",
        width: "100%",
      }}
    >
      <div style={{ margin: "32px" }}>
        <SocialMedia footer iconWidth="48px" />
      </div>
      <div style={{ color: "white", background: "#ff8674", marginBottom: "32px", padding: "16px", borderRadius: "48px" }}>Ava J ShareAlike est. 2016</div>
    </Flex.ColumnCenter>
  );
};

export default Footer;
