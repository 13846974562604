import SocialMedia from "../components/SocialMedia";

const ConnectWithMe = () => {
  return (
    <>
      <h2>Connect With Me</h2>
      <SocialMedia />
    </>
  );
};

export default ConnectWithMe;
