import PageWrapper from "../components/PageWrapper";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import ContentWrapper from "../components/ContentWrapper";

const ava001 = [
  {
    original: "https://vangogh.teespring.com/v3/image/6Xk07qnS4Yxv89ToTKwGBTVUELk/800/800.jpg",
    originalTitle: "Classic Tank",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/pJ1FTEL8TDY783UsMWbaV8GA9IQ/800/800.jpg",
    originalTitle: "Flowy Tank",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/R6JkfYN2HeCX9kxX9p1fCapQOy8/800/800.jpg",
    originalTitle: "Crew Sweater",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/tk5apzTq_OkAe4u7bzkruar41b8/800/800.jpg",
    originalTitle: "Classic Tee",
  },
];

const ava002 = [
  {
    original: "https://vangogh.teespring.com/v3/image/48_IxjeBKVT3W1R8l9nUQEmVAjg/800/800.jpg",
    originalTitle: "Classic Tank",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/prk8rYNojwBAJnOx0bisBvSiy0Q/800/800.jpg",
    originalTitle: "Flowy Tank",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/m-3tEV6hbubajbjTbiU-sghKkps/800/800.jpg",
    originalTitle: "Crew Sweater",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/GIJy5T_TDGnjfCa1I5br2IZtNxc/800/800.jpg",
    originalTitle: "Classic Tee",
  },
];

const ava003 = [
  {
    original: "https://vangogh.teespring.com/v3/image/VgJVkcea0LR3WhOB5loV1TwZgEQ/800/800.jpg",
    originalTitle: "All Over Print Sweater",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/wO2DRe6HtrbGMwQjKja-Xh4pAA4/800/800.jpg",
    originalTitle: "All Over Print Tee",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/Bkrd9fow2zAkuTUC3YzYMmbDOZ8/800/800.jpg",
    originalTitle: "Classic Hoodie",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/iLjhovmiINQWVxjGM8RArR_vf7o/800/800.jpg",
    originalTitle: "Classic Tee",
  },
  {
    original: "https://vangogh.teespring.com/v3/image/j76VdjEDYbmAKQ2bp8oO0McUjA4/800/800.jpg",
    originalTitle: "Classic Tank",
  },
];

const StoreRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <h1>
          <a href="https://ava-j-sharealike.creator-spring.com/" target="_blank" rel="noreferrer">
            Go to store
          </a>
        </h1>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap" }}>
          <div style={{ padding: "8px", width: "256px", height: "auto" }}>
            <ImageGallery autoPlay items={ava001} slideInterval={5000} />
          </div>
          <div style={{ padding: "8px", width: "256px", height: "auto" }}>
            <ImageGallery autoPlay items={ava002} slideInterval={5000} />
          </div>
          <div style={{ padding: "8px", width: "256px", height: "auto" }}>
            <ImageGallery autoPlay items={ava003} slideInterval={5000} />
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default StoreRoute;
