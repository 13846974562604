const HireMe = () => {
  return (
    <div style={{ width: "90%", outline: "2px solid black", padding: "16px", margin: "16px" }}>
      <h2>Hire Me</h2>
      <div style={{ padding: "16px" }}>
        For bookings, contact me at <a href="mailto:bookings@dragqueenava.com">bookings</a>.
      </div>
      <div style={{ padding: "16px" }}>
        For costume commissions, contact me at <a href="mailto:commissions@dragqueenava.com">commissions</a>.
      </div>
    </div>
  );
};

export default HireMe;
