import styled from "styled-components";

const FlexRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Flex = { RowCenter: FlexRowCenter, ColumnCenter: FlexColumnCenter };

export default Flex;
