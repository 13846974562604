import ContentWrapper from "../components/ContentWrapper";
import ImageGallery from "react-image-gallery";
import PageWrapper from "../components/PageWrapper";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MistyBenefit from "../img/misty-benefit.jpg";
import DemDamBoys from "../img/the-boys-bistro.jpg";

const posters = [
  {
    original: DemDamBoys,
    originalTitle: "Dem Dam Boyz- Bistro - Bloomington, IL",
  },
  {
    original: MistyBenefit,
    originalTitle: "A Benefit for Misty Midler ShareAlike - Clique - Springfield, IL",
  },
];

const events = [
  {
    title: "ShareAlike Show - Donnie's Pizza - Decatur, IL",
    start: new Date("February 11, 2023 22:00:00"),
    end: new Date("February 12, 2023 00:00:00"),
    allDay: false,
  },
  {
    title: "Dem Dam Boyz- Bistro - Bloomington, IL",
    start: new Date("February 17, 2023 23:00:00"),
    end: new Date("February 18, 2023 00:00:00"),
    allDay: false,
  },
  {
    title: "A Benefit for Misty Midler ShareAlike - Clique - Springfield, IL",
    start: new Date("February 24, 2023 22:30:00"),
    end: new Date("February 25, 2023 00:00:00"),
    allDay: false,
  },
];

const localizer = dayjsLocalizer(dayjs);

const MyCalendar = () => (
  <div>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      min={new Date("February 1, 2023")}
      max={new Date("February 28, 2023")}
      view={Views.AGENDA}
    />
  </div>
);

const EventsRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <h2>Upcoming Shows</h2>
        <ImageGallery autoPlay items={posters} slideInterval={5000} />
        <h2>Calendar</h2>
        <MyCalendar />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default EventsRoute;
