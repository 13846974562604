import Flex from "./Flex";
import styled from "styled-components";
import FacebookIcon from "../img/icons/facebook-icon.png";
import InstagramIcon from "../img/icons/instagram-icon.png";
import FacebookMessengerIcon from "../img/icons/facebook-messenger-icon.png";
import EmailIcon from "../img/icons/email-icon.png";
import MinecraftIcon from "../img/icons/minecraft-icon.png";

const IconWrapper = styled.div`
  width: ${(props) => props.width || "96px"};
  margin: 16px;
`;

const IconLink = ({ src, alt, iconWidth = "96px", url }) => {
  return url ? (
    <IconWrapper width={iconWidth}>
      <a href={url} target="_blank" rel="noreferrer">
        <img width="100%" height="auto" alt={alt} src={src} />
      </a>
    </IconWrapper>
  ) : (
    <></>
  );
};

const Icon = ({ iconWidth, alt, src }) => {
  return (
    <IconWrapper width={iconWidth} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img width="100%" height="auto" alt={alt} src={src} />
      <div>dragqueenava</div>
    </IconWrapper>
  );
};

const SocialMedia = ({ footer }) => {
  const iconWidth = footer ? "48px" : "96px";

  return (
    <Flex.RowCenter style={{ flexWrap: "wrap",justifyContent: "space-evenly" }}>
      <IconLink alt="Link to Ava J ShareAlike's Facebook" src={FacebookIcon} iconWidth={iconWidth} url="https://www.facebook.com/avajsharealike" />
      <IconLink alt="Link to Ava J ShareAlike's Instagram" src={InstagramIcon} iconWidth={iconWidth} />
      <IconLink alt="Link to Ava J ShareAlike's Messenger" src={FacebookMessengerIcon} iconWidth={iconWidth} url="https://m.me/avajsharealike" />
      <IconLink alt="Link to Ava J ShareAlike's email" src={EmailIcon} iconWidth={iconWidth} url="mailto:ava@dragqueenava.com" />
      {footer ? <></> : <Icon alt="Link to Ava J ShareAlike's Minecraft" src={MinecraftIcon} iconWidth={iconWidth} />}
    </Flex.RowCenter>
  );
};

export default SocialMedia;
