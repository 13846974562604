import DQAFooter from "./components/Footer";
import Header from "./components/Header";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import EventsRoute from "./routes/EventsRoute";
import ContactRoute from "./routes/ContactRoute";
import LandingRoute from "./routes/LandingRoute";
import GalleryRoute from "./routes/GalleryRoute";
import ProjectsRoute from "./routes/ProjectsRoute";
import StoreRoute from "./routes/StoreRoute";


const AppWrapper = () => {
  return (
    <>
      <Header />
      <Outlet />
      <DQAFooter />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper />,
    children: [
      {
        path: "/",
        element: <LandingRoute />,
      },
      {
        path: "/events",
        element: <EventsRoute />,
      },
      {
        path: "/contact",
        element: <ContactRoute />,
      },
      {
        path: "/gallery",
        element: <GalleryRoute />,
      },
      {
        path: "/projects",
        element: <ProjectsRoute />,
      },
      {
        path: "/store",
        element: <StoreRoute />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="App" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
