import styled from "styled-components";
import Flex from "./Flex";

const StyledContentWrapper = styled(Flex.ColumnCenter)`
  padding: 16px;
  width: 768px;
  max-width: 90vw;
  min-height: 50vh;
  background: white;
`;

const ContentWrapper = ({ children }) => {
  return <StyledContentWrapper>{children}</StyledContentWrapper>;
};

export default ContentWrapper;
