import ContentWrapper from "../components/ContentWrapper";
import PageWrapper from "../components/PageWrapper";

const GalleryRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <div>Gallery Placeholder</div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default GalleryRoute;
