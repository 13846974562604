import ContentWrapper from "../components/ContentWrapper";
import PageWrapper from "../components/PageWrapper";

const ProjectsRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <h2>Costumes</h2>
        <div>Placeholder for costumes</div>
        <h2>Software Development</h2>
        <div>Placeholder for software development</div>
        <h2>Apparel</h2>
        <div>Placeholder for apparel</div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ProjectsRoute;
