import styled from "styled-components";
import Flex from "./Flex";

const StyledPageWrapper = styled(Flex.ColumnCenter)`
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
`;

const PageWrapper = ({ children }) => {
  return <StyledPageWrapper>{children}</StyledPageWrapper>;
};

export default PageWrapper;
