import { Link, useLocation } from "react-router-dom";
import AvaHeader from "../img/ava-header.png";
import styled from "styled-components";
import Flex from "./Flex";

const HeaderWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background: linear-gradient(127deg, rgb(205,236,218), rgba(0, 0, 0, 0) 70.71%), linear-gradient(217deg, rgb(255,134,116), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgb(252,224,167), rgba(0, 0, 0, 0) 70.71%);
  display: block;
`;

const NavItemsWrapper = styled(Flex.RowCenter)`
  overflow-x: auto;
  white-space: nowrap;
`;

const HeroImageWrapper = styled(Flex.RowCenter)`
  height: 256px;
`;

const StyledNavItem = styled.div`
  a {
    display: inline-block;
    text-align: center;
    text-decoration: none;

    color: ${(props) => (props.currentPage ? "#ff8674;" : "white;")}
    font-size: 32px;
    font-weight: 700;
    padding: 20px;
    background: ${(props) => (props.currentPage ? "white;" : "#ff8674;")}
    border-radius: 48px;
    margin-left: 24px;
    margin-right: 24px;
    -webkit-text-stroke: 1px ${(props) => (props.currentPage ? "white;" : "#c36849;")}
    border: 5px solid ${(props) => (props.currentPage ? "white;" : "#C36849;")};
  }

  a:hover {
    text-decoration: underline;
    background: white;
    color: #ff8674;
    -webkit-text-stroke: 1px white;
  }
`;

const StyledFirstNavItem = styled(StyledNavItem)`
  a {
    margin-left: 0px;
  }
`;

const StyledLastNavItem = styled(StyledNavItem)`
  a {
    margin-left: 24px;
  }
`;

const FirstNavItem = ({ to, children, currentPage }) => {
  return (
    <StyledFirstNavItem currentPage={currentPage}>
      <Link to={to}>{children}</Link>
    </StyledFirstNavItem>
  );
};

const NavItem = ({ to, children, currentPage }) => {
  return (
    <StyledNavItem currentPage={currentPage}>
      <Link to={to}>{children}</Link>
    </StyledNavItem>
  );
};

const LastNavItem = ({ to, children, currentPage }) => {
  return (
    <StyledLastNavItem currentPage={currentPage}>
      <Link to={to}>{children}</Link>
    </StyledLastNavItem>
  );
};

const Header = () => {
  let location = useLocation();

  return (
    <HeaderWrapper>
      <NavItemsWrapper style={{ margin: "16px", alignItems: "end" }}>
        <FirstNavItem to="/">
          <HeroImageWrapper>
            <img height={"100%"} width={"auto"} alt="Navigate to home" src={AvaHeader} style={{ borderRadius: "48px" }} />
          </HeroImageWrapper>
        </FirstNavItem>
        <NavItem currentPage={location.pathname === "/store"} to="/store">
          Store
        </NavItem>
        <NavItem currentPage={location.pathname === "/events"} to="/events">
          Events
        </NavItem>
        <LastNavItem currentPage={location.pathname === "/contact"} to="/contact">
          Contact
        </LastNavItem>
      </NavItemsWrapper>
    </HeaderWrapper>
  );
};

export default Header;
