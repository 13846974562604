import styled from "styled-components";
import Flex from "./Flex";
import AvaLanding from "../img/ava-landing.jpeg";

const LandingWrapper = styled(Flex.ColumnCenter)`
  margin-top: 32px;
  margin-bottom: 64px;
  width: 100%;
`;

const Landing = () => {
  return (
    <LandingWrapper>
      <div>
        <img alt="Ava J ShareAlike" width={"100%"} height={"auto"} src={AvaLanding} style={{ float: "left", marginRight: "32px", marginBottom: "8px", maxWidth: "425px" }} />
        <div style={{ fontSize: "20px", lineHeight: "40px", letterSpacing: "2px" }}>
          Ava J ShareAlike is a Bloomington, IL based drag queen. Ava started doing drag in 2016, and has been performing across central Illinois ever since.
          She is the drag daughter of Bloomington, IL based drag queen, Sharon ShareAlike. Ava's home bar is the Bistro located in downtown Bloomington where
          drag shows occur on the first and third Friday of every month. When not in drag, Ava works as a bartender at this bar, and as a software engineer.
        </div>
      </div>
    </LandingWrapper>
  );
};

export default Landing;
