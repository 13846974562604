import ContentWrapper from "../components/ContentWrapper";
import Landing from "../components/Landing";
import PageWrapper from "../components/PageWrapper";

const LandingRoute = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <Landing />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default LandingRoute;
